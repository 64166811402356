import React from 'react';
import {ReactComponent as Tick} from '../assets/tick.svg';

const ServiceCardList = () =>{
    
  const cardList = [
      {
        title: "Pre-feasibility and feasibility studies."
      },
      {
        title: "Field investigations."
      },
      {
        title: "Environmental & social impact assessment."
      },
      {
        title: "Geo technical investigations"
      },
      {
        title: "Hydrological and drainage studies."
      },
      {
        title: "Route selection, surveying and mapping."
      },
      {
        title: "Review of Designs and Tender Documents."
      },
      {
        title: "Construction supervision."
      },
      {
        title: "Innovative engineering solutions"
      },
      {
        title: "Sustainable development."
      },
      {
        title: "Project Monitoring and Evaluation."
      },
      {
        title: "Quality Assurance/Technical Audits"
      },
      {
        title: "Financial & Economic Analysis"
      },
      {
        title: "Legal Policy and Institutional Support"
      },
      {
        title: "Tender Document Preparation and Cost"
      },
      {
        title: "Infrastructure Design"
      },
      {
        title: "Road Detail Design"
      }
    ]
        
    return cardList.map((data,id) =>{
        return (
            <div className={`card hidden`} key={id} id='services-card'>
            {/* <img src={tick} alt="the tick" className='tick'/> */}
            <Tick className='tick'/>
            <p>
              {data.title}
            </p>
          </div>
        )
    })
}

  export default ServiceCardList;