import React from 'react'
import { Link } from 'react-router-dom'
import * as ROUTES from '../constants/routes'

const Error = () => {
  return (
    <div className='error-page'>
      <h1>Sorry, the page you are looking for doesn't exist.</h1>
      <h1>¯\_(ツ)_/¯</h1>
      <Link to={ROUTES.HOME}>Go Home</Link>
    </div>
  )
}

export default Error