import React, { useEffect } from 'react'
import '../sass/services.scss';
import ServiceCardList from './ServiceCardList';


const Services = () => {

  const observer = new IntersectionObserver((entries)=>{
    entries.forEach(entry=>{
      if(entry.isIntersecting){
        entry.target.classList.add('show')
      }
      // else{
      //   entry.target.classList.remove('show')
      // }
    })
  },{threshold:[0.5,1]})

  useEffect(()=>{
    window.scrollTo(0,0)
    const cards = Array.from(

      document.querySelectorAll('.card-list .card')
    )

    cards.forEach(el=>{
      observer.observe(el)
    })


  },[])

  return (
    <main className='services-page'>
      <section className='title d-flex justify-content-between'>
        <div className='title-left col-12 col-lg-6 text-left'>
          <h1>Services</h1>
        </div>
        <div className='title-right col-1'>

        </div>
      </section>
      <section className='card-list col-10 p-0 m-auto'>
        <ServiceCardList/>
      </section>
    </main>

  )

}



export default Services